











































import useMarkers from "@/use/mapMarkers";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const { getMarker } = useMarkers();

    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        {
          value: "marker",
          text: root.$tc("layout.misc.marker"),
          align: "right",
          width: 40,
        },
        {
          value: "city",
          text: root.$tc("layout.misc.city"),
          align: "right",
          width: 200,
        },
        {
          value: "category",
          text: root.$tc("layout.misc.category"),
          align: "right",
          width: 40,
        },
        { text: "", value: "actions", align: "right", width: 100 },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/conference-object`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
          },
        })
        .then(({ data }) => {
          state.items = data;
          state.total = data.length;
          state.empty = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`conference-object/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData, deleteItem, getMarker };
  },
});
